import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { MessageDialogService } from 'src/app/util/services/message-dialog.service';
import { environment } from 'src/environments/environment';
import { IssuesService } from './issues.service';

@Injectable({
  providedIn: 'root'
})
export class IssueNotificationService {
  private hubConnection?: HubConnection;
  private pendingIssuesCount: number =0;
  private issuesUrl = "/issues";

  constructor(
    private _issuesService: IssuesService,
    private messageDialogService: MessageDialogService,
    private _snackBar: MatSnackBar,
    private _router: Router,
  ) { }

  getPendingIssuesCountValue():number{
    return this.pendingIssuesCount;
  }

  initialize() {
    if (!this.hubConnection) {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(environment.apiUrl + 'pushNotification').build();

      this.hubConnection.start().then(() => {
        console.log('connection started');
        this.getPendingIssuesCount();
      }).catch(err => console.log(err));

      this.hubConnection.onclose(() => {
        setTimeout(() => {
          console.log('try to re start connection');
          this.hubConnection?.start().then(() => {
            console.log('connection re started');
            this.getPendingIssuesCount();
          }).catch(err => console.log(err));
        }, 20000);
      });

      this.hubConnection.on('privateMessageMethodName', (data) => {
        console.log('private Message:' + data);
      });

      this.hubConnection.on('newIssueCreated', (data) => {
        console.log('public Message:' + data);
        this.getPendingIssuesCount();
        this.showNotificationMessage(data);
      });

      this.hubConnection.on('issueClosed', (data) => {
        this.getPendingIssuesCount();
      });

      this.hubConnection.on('clientMethodName', (data) => {
        console.log('server message:' + data);
      });

      this.hubConnection.on('WelcomeMethodName', (data) => {
        console.log('client Id:' + data);
        this.hubConnection?.invoke('GetDataFromClient', 'abc@abc.com', data).catch(err => console.log(err));
      });
    }
  }

  stopConnection() {
    this.hubConnection?.stop().then(() => {
      console.log('stopped');
    }).catch(err => console.log(err));
  }
  
  getPendingIssuesCount() {
    this._issuesService.getPendingIssuesCount().subscribe({
      next: (res) => {
        //console.log(res);
        this.pendingIssuesCount=res.count;
      },
      error: (e: any) => {
        console.log(e);
        if (e === 'Unauthorized') {
          this.messageDialogService.openDialogOneButton('Unauthorized', 'You don\'t have permissions to perform this action', 'Continue');
        } else {
          this.messageDialogService.openDialogOneButton('Error', e.errors, 'Continue');
        }
      }
    });
  }

  private showNotificationMessage(message:string) {
    let notificationRef =this._snackBar.open(message, 'Ok', {
      horizontalPosition: "right",
      verticalPosition: "bottom",
    });
    notificationRef.onAction().subscribe(() => {
      this.goToIssuesPage();
    });
  }
  private goToIssuesPage(){
    if(!this._router.url.includes(this.issuesUrl)){
      this._router.navigateByUrl(this.issuesUrl);
    }
  }
}
