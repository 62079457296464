import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageDialogService } from 'src/app/util/services/message-dialog.service';
import { AuthService } from '../../services/auth.service';
import { InvalidTokenDialogComponent } from '../invalid-token-dialog/invalid-token-dialog.component';

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent implements OnInit {

  @Input() create: boolean = false;
  @Input() email: string = '';
  @Input() token: string = '';
  //hide / display passwords
  hide1 = true;
  hide2 = true;



  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = this.passwordForm.get('password')?.value;
    let confirmPass = this.passwordForm.get('confirmPassword')?.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  passwordForm: FormGroup = new FormGroup({
    password: new FormControl('', Validators.required),
    confirmPassword: new FormControl('', Validators.required)
  });

  constructor(
    private _router: Router,
    public dialog: MatDialog,
    private authService: AuthService,
    private messageDialogService: MessageDialogService,
  ) {

  }
  ngOnInit() {
    this.initForms();
  }
  initForms() {
    this.passwordForm = this.getFormInitialValue();
  }

  getFormInitialValue() {
    return new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]),
      confirmPassword: new FormControl('', [Validators.required, this.checkPasswords])
    });
  }

  getErrorMessage(control: string) {
    let field = this.passwordForm.get(control);
    switch (control) {
      case 'password':
        return field?.errors ? 'Password required between 6 - 15 characters long' : '';
      case 'confirmPassword':
        return field?.hasError('required') ? 'Password required' : field?.hasError('notSame') ? 'Passwords are not equal' : '';
      default:
        return '';
    }
  }

  isInputValid(inputName: string) {
    return this.passwordForm.get(inputName)?.invalid && this.passwordForm.get(inputName)?.dirty;
  }
  getInputMessage(inputName: string) {
    return this.passwordForm.get(inputName)?.dirty ? this.getErrorMessage(inputName) : '';
  }
  getSubmitButtonLabel() {
    return this.create ? "CREATE PASSWORD" : "CHANGE PASSWORD";
  }

  goToLogin() {
    this._router.navigateByUrl('/login');
  }

  openDialog(email: string, success: boolean, errorMessage: string): void {
    const dialogRef = this.dialog.open(InvalidTokenDialogComponent, {
      width: '500px',
      data: { email: email, success: success, errorMessage: errorMessage },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.goToLogin();
    });
  }

  openInvalidDataDialog(): void {
    this.messageDialogService
      .openDialogOneButton('Invalid Data', 'The email or token parameters are invalid', 'Continue')
      .subscribe(result => {

      });
  }

  openOperationSuccessDialog(): void {
    this.messageDialogService
      .openDialogOneButton('Success', 'The password has been updated correctly', 'Continue')
      .subscribe(result => {
        this.goToLogin();
      });
  }

  onSubmit(formDirective: FormGroupDirective) {
    let password = this.passwordForm.get("password");
    let confirmPassword = this.passwordForm.get("confirmPassword");

    if (confirmPassword?.errors ? true : false || password?.errors ? true : false) {
      return
    }

    if (this.create) {
      this.createPassword(password?.value);
    } else {
      this.changePassword(password?.value);
    }
  }

  private changePassword(password: string) {
    this.authService.changePassword(this.email, this.token, password).subscribe({
      next: res => {
        //Change success
        this.openOperationSuccessDialog();
      },
      error: e => {
        this.openInvalidDataDialog();
      }
    });
  }

  private createPassword(password: string) {
    this.authService.createPassword(this.email, this.token, password).subscribe({
      next: res => {
        //Change success
        this.openOperationSuccessDialog();
      },
      error: e => {
        this.openInvalidDataDialog();
      }
    });
  }
}
