import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { MenuCardComponent } from './components/menu-card/menu-card.component';
import { MenuComponent } from './components/menu/menu.component';
import { MessageDialogOneButtonComponent } from './components/message-dialog-one-button/message-dialog-one-button.component';
import { MessageDialogContinueCancelButtonsComponent } from './components/message-dialog-continue-cancel-buttons/message-dialog-continue-cancel-buttons.component';
import { HourSelectorComponent } from './components/hour-selector/hour-selector.component';



@NgModule({
  declarations: [
    NavMenuComponent,
    MenuCardComponent,
    MenuComponent,
    MessageDialogOneButtonComponent,
    MessageDialogContinueCancelButtonsComponent,
    HourSelectorComponent,
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
  ],
  exports: [
    NavMenuComponent,
    MenuComponent,
    HourSelectorComponent,
  ]
})
export class UtilModule { }
