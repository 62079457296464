<div id="fullwidth">
	<app-background-carousel></app-background-carousel>
	<div class="formsection">
		<form #formDirective="ngForm" (ngSubmit)="onSubmit(formDirective)" [formGroup]="this.loginForm">
			<div class="email">
				<input placeholder="Email" formControlName="email" required>
				<div *ngIf="loginForm.get('email')?.invalid && 
					(loginForm.get('email')?.dirty || loginForm.get('email')?.touched)" class="err-msg">
					<p><span class="err-icon">!</span> {{getErrorMessage("email")}}</p>
				</div>
			</div>
			<div class="password">
				<input [type]="hide ? 'password' : 'text'" placeholder="Password" formControlName="password" required>
				<div class="icon" (click)="hide = !hide"><img
						src="../../../../assets/Login/{{hide? 'hide.png' : 'show.png'}}"></div>
				<div *ngIf="loginForm.get('password')?.invalid && 
					(loginForm.get('password')?.dirty || loginForm.get('password')?.touched)" class="err-msg">
					<p><span class="err-icon">!</span> {{getErrorMessage("password")}}</p>
				</div>
			</div>
			<button type="submit" id="submit">Sign in</button>
			<p class="forget">Forget Password? <a href="javascript: void(0);" (click)="goToResetPassword()">Reset</a>
			</p>
		</form>
	</div>
</div>