import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageDialogService } from 'src/app/util/services/message-dialog.service';
import { PasswordResetDialogComponent } from '../../components/password-reset-dialog/password-reset-dialog.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  passwordResetForm: FormGroup = this.getFormInitialValue();

  constructor(
    private _router: Router,
    public dialog: MatDialog,
    private authService: AuthService,
    private messageDialogService: MessageDialogService,
  ) {
  }
  ngOnInit() {
    this.passwordResetForm = this.getFormInitialValue();
  }
  getFormInitialValue() {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  getErrorMessage(control: string) {
    let field = this.passwordResetForm.get(control);
    switch (control) {
      case 'email':
        return field?.hasError('required') ? 'Email required' : field?.hasError('email') ? 'Enter a valid email' : '';
      default:
        return '';
    }
  }

  onSubmit(formDirective: FormGroupDirective) {
    let email = this.passwordResetForm.get("email");
    if(email?.errors){
      return
    }
    this.executePasswordReset(email?.value);
  }

  executePasswordReset(email:string){
    this.authService.requestPasswordReset(email).subscribe({
      next: res =>{
        this.openDialog(this.passwordResetForm.get("email")?.value,true,"");
      },
      error: e=>{
        console.log(e);
        if (e==='Unauthorized'){
          this.messageDialogService.openDialogOneButton('Unauthorized', 'Invalid email', 'Continue');
        } else {
          this.messageDialogService.openDialogOneButton('Error', e.errors, 'Continue');
        }
      } 
    });
  }

  isInputValid(inputName: string) {
    return this.passwordResetForm.get(inputName)?.invalid && this.passwordResetForm.get(inputName)?.dirty;
  }
  getInputMessage(inputName: string) {
    return this.passwordResetForm.get(inputName)?.dirty ? this.getErrorMessage(inputName) : '';
  }
  
  goToResetPassword() {
    this._router.navigateByUrl('/password-reset');
  }

  openDialog(email:string, success:boolean, errorMessage:string): void {
    const dialogRef = this.dialog.open(PasswordResetDialogComponent, {
      width: '500px',
      data: {email: email, success: success, errorMessage: errorMessage},
    });

    dialogRef.afterClosed().subscribe(result => {
      this._router.navigateByUrl('/login');
    });
  }
}
