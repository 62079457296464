import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanLoadByRoleGuard } from './guards/can-load-by-role.guard';

const dashboardUser ='ANY';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), data: {role: [dashboardUser]}, canLoad: [CanLoadByRoleGuard]},
  { path: 'store', loadChildren: () => import('./store/store.module').then(m => m.StoreModule), data: {role: [dashboardUser]}, canLoad: [CanLoadByRoleGuard] },
  { path: 'schedule-drip', loadChildren: () => import('./schedule-drip/schedule-drip.module').then(m => m.ScheduleDripModule), data: {role: [dashboardUser]}, canLoad: [CanLoadByRoleGuard] },
  { path: 'issues', loadChildren: () => import('./issues/issues.module').then(m => m.IssuesModule), data: {role: [dashboardUser]}, canLoad: [CanLoadByRoleGuard] },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), data: {role: [dashboardUser]}, canLoad: [CanLoadByRoleGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
