import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { catchError, Observable, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { UserAuthDataService } from '../auth/services/user-auth-data.service';
import { AuthService } from '../auth/services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private userAuthDataService: UserAuthDataService,
        private _router: Router,
        private authService: AuthService,
    ) { }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //console.log(httpRequest.url);
        if (httpRequest.url.includes('refresh-token') || !this.isLoggedUser()) {
            return next.handle(httpRequest);
        }
        
        if(this.isLoggedUser() && !httpRequest.headers.has('Authorization')){
            httpRequest = httpRequest.clone({
                setHeaders: this.getHeaders(httpRequest)
            });
        }

        if (!this.userAuthDataService.hasTokenExpired()) {
            return next.handle(httpRequest);
        }

        if (this.userAuthDataService.hasRefreshTokenExpired()) {
            this.goToLogin();
            return next.handle(httpRequest.clone({
                setHeaders: {}
            }));
        }
        //https://stackoverflow.com/questions/45345354/how-use-async-service-into-angular-httpclient-interceptor
        return this.authService.refreshToken().pipe(switchMap((tokenData: any) => {
            this.userAuthDataService.saveToken(tokenData.accessToken, tokenData.refreshToken, tokenData.expiration);
            return next.handle(
                httpRequest.clone({
                    setHeaders: this.getHeaders(httpRequest)
                })
            );
        }),
            catchError((error: any) => {
                console.log(error);
                this.goToLogin();
                return next.handle(httpRequest.clone({
                    setHeaders: this.getEmptyHeaders()
                }));
            })
        );
    }

    goToLogin() {
        this._router.navigateByUrl('/login');
    }
    getEmptyHeaders() {
        return { 'Content-Type': 'application/json' };
    }
    getHeaders(httpRequest: HttpRequest<any>):any {
        let contentTypeText = 'Content-Type';
        if(httpRequest.headers.has(contentTypeText)){
            return {'Content-Type': httpRequest.headers.get(contentTypeText), 'Authorization': 'Bearer ' + this.userAuthDataService.getToken() };
        }
        return {'Authorization': 'Bearer ' + this.userAuthDataService.getToken() };
    }
    isLoggedUser(){
        var token = this.userAuthDataService.getToken();
        return  token!= '' && token != null && token != undefined;
    }
}