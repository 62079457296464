import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItemType } from '../../models/menu-item-type-enum';

@Component({
  selector: 'app-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.scss']
})

export class MenuCardComponent implements OnInit {

  @Input() type: MenuItemType = MenuItemType.settings;
  @Input() selected: boolean = false;
  @Input() url: string = "";
  @Input() totalNotifications: number = 0;
  @Input() disabled: boolean | undefined = false;
  @Input() external: boolean | undefined = false;

  // imageSrc:string = "assets/ButtonsMenu/";
  imageSrc: string = "assets/ButtonsMenuBranding/";
  states = {
    regular: "regular",
    hover: "hover",
    selected: "selected"
  }
  sizes = {
    small: "s",
    medium: "m",
    large: "l"
  }
  currentState = "";
  size = "m";

  badgeSizes = new Map([
    [this.sizes.small, 'small'],
    [this.sizes.medium, 'medium'],
    [this.sizes.large, 'large'],
  ]);

  constructor(
    private _router: Router,
  ) { }


  ngOnInit(): void {
    this.setRegularState();
  }

  getImage() {
    this.setImageSize();
    let src = this.imageSrc + this.type + "/" + this.currentState + ".svg";
    return src
  }

  setHoverState() {
    this.currentState = this.selected ? this.states.selected : this.states.hover;
  }
  setRegularState() {
    this.currentState = this.selected ? this.states.selected : this.states.regular;
  }
  setImageSize() {
    let currentSize = window.innerWidth;
    if (currentSize <= 500) {
      this.size = this.sizes.small;
      return;
    }
    if (currentSize > 500 && currentSize < 1400) {
      this.size = this.sizes.medium;
      return;
    }
    if (currentSize >= 1400) {
      this.size = this.sizes.large;
      return;
    }
  }
  redirect() {
    if (this.disabled === true) return;
    if (!this.selected) {
      if (!this.external) {
        this._router.navigateByUrl(this.url);
      }
      else {
        window.open(this.url, "_blank");
      }
    }
  }

}
