import { Component, OnInit } from '@angular/core';
import { style, animate, animation, trigger, transition, useAnimation } from "@angular/animations";

// =========================
// Fade
// =========================
export const fadeIn = animation([
  style({ opacity: 0 }), // start state
  animate(
    "{{time}} cubic-bezier(0.785, 0.135, 0.15, 0.86)",
    style({ opacity: 1 })
  )
]);

export const fadeOut = animation([
  animate(
    "{{time}} cubic-bezier(0.785, 0.135, 0.15, 0.86)",
    style({ opacity: 0 })
  )
]);

@Component({
  selector: 'app-background-carousel',
  templateUrl: './background-carousel.component.html',
  styleUrls: ['./background-carousel.component.scss'],
  animations: [
    trigger("slideAnimation", [
      /* fade */
      transition("void => fade", [
        useAnimation(fadeIn, { params: { time: "500ms" } })
      ]),
      transition("fade => void", [
        useAnimation(fadeOut, { params: { time: "500ms" } })
      ])
    ])
  ]
})
export class BackgroundCarouselComponent implements OnInit {

  baseImageUrl: string = "../../../../assets/Login/Carousel1/";
  imageName: string = "image_";
  size = '';
  sizes = {
    small: "s",
    medium: "m",
    large: "l"
  };
  slideFolders = ['1', '2', '3', '4'];

  currentSlide = 0;
  animationType = "fade";

  constructor() { }

  ngOnInit(): void {
    this.setImageSize();
    //set interval for slide change
    setInterval(() => { this.onNextClick() }, 5 * 1000);
  }

  getImage(folder: string): string {
    return "url('" + this.baseImageUrl + folder + "/" + this.imageName + this.size + ".png')";
  }
  getSlides() {
    let slides: string[] = [];
    this.slideFolders.forEach(folder => {
      slides.push(this.getImage(folder));
    });
    return slides;
  }

  setImageSize() {
    let currentSize = window.innerWidth;
    if (currentSize <= 500) {
      this.size = this.sizes.small;
      return;
    }
    if (currentSize > 500 && currentSize < 1400) {
      this.size = this.sizes.medium;
      return;
    }
    if (currentSize >= 1400) {
      this.size = this.sizes.large;
      return;
    }
  }
  onNextClick() {
    let next = this.currentSlide + 1;
    this.currentSlide = next === this.slideFolders.length ? 0 : next;
  }
}

