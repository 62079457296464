import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { NetworkError } from 'src/app/util/models/networkError';
import { environment } from 'src/environments/environment';
import { AcknowledgeIssue } from '../model/AcknowledgeIssue';
import { Issue } from '../model/Issue';

@Injectable({
  providedIn: 'root'
})
export class IssuesService {

  private urlController = 'Issue';

  constructor(
    private http : HttpClient,
  ) { }
  
  getUrl(_url: string): string {
    return environment.apiUrl + _url;
  }
  getHeaders() {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  }
  
  getIssueList():Observable<Array<Issue> | any>{
    return this.http.get(
      this.getUrl(this.urlController),
      this.getHeaders()
    ).pipe(
      catchError(NetworkError.handleError)
    );
  }
  
  getPendingIssuesList():Observable<Array<Issue> | any>{
    return this.http.get(
      this.getUrl(`${this.urlController}/Filter/Pending`),
      this.getHeaders()
    ).pipe(
      catchError(NetworkError.handleError)
    );
  }
  
  getPendingIssuesCount():Observable<any>{
    return this.http.get(
      this.getUrl(`${this.urlController}/Filter/Pending/Count`),
      this.getHeaders()
    ).pipe(
      catchError(NetworkError.handleError)
    );
  }
  
  getAcknowledgeIssuesList(start:Date, end:Date):Observable<Array<Issue> | any>{
    let param='';
    if(!this.isNullOrUndefined(start) && !this.isNullOrUndefined(end)){
      start.setHours(0,0,0,0);
      end.setHours(23,59);
      param+=`?start=${start.toISOString()}&end=${end.toISOString()}`
    }
    
    //let param = // define if parameteers can be null and apply them to the url
    return this.http.get(
      this.getUrl(`${this.urlController}/Filter/Acknowledge${param}`),
      this.getHeaders()
    ).pipe(
      catchError(NetworkError.handleError)
    );
  }
  
  acknowledgeIssue(data:AcknowledgeIssue):Observable<Issue | any>{
    let body = data;
    return this.http.put(
      this.getUrl(`${this.urlController}/${data.id}/Acknowledge`),
      body,
      this.getHeaders()
    ).pipe(
      catchError(NetworkError.handleError)
    );
  }

  private isNullOrUndefined(value : any){
    return value === null || value === undefined;
  }
}
