import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs/internal/observable/throwError";

export class NetworkError {

    static handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        console.error('An error occurred:', error.error.message);
        
        return throwError('A client-side or network error occurred');
      } 
      console.error(
          `Backend returned code ${error.status}, body was: `);
      console.error(error.error)
      switch(error.status){
        case 500:
          return throwError({errors: 'Internal server error, please contact customer support'});
        case 400:
        case 409:
          if(typeof(error.error?.errors) === 'object'){
            let errorResponse = '';
            for(var key in error.error?.errors){
              errorResponse+= ' ' + error.error?.errors[key];
            }
            return throwError({errors: errorResponse.trim()});
          }
          return throwError(error.error?.errors? error.error : {errors: error.error});
        case 401:
          //return throwError(() => new Error(error.error?.detail? error.error.detail : 'Unauthorized'));
          return throwError(error.error?.errors? error.error : 'Unauthorized');
        case 404:
          return throwError(error.error?.errors? error.error : {errors: 'Not found'});
        default:
          return throwError({errors: 'Service not found, please contact customer support'});
      }
    }
  }
  