<div class="img-container">
    <div>
        <img class="menu-flag" *ngIf="selected" [src]="getImage()">
    </div>

    <div [matBadge]="totalNotifications" matBadgePosition="above after"
        [matBadgeHidden]="totalNotifications<=0 || selected" matBadgeSize="large">
        <img class="menu-image" (click)="redirect()" (mouseover)="setHoverState()" (mouseout)="setRegularState()"
            [src]="getImage()" [alt]="type">
    </div>

</div>