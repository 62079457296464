import { Injectable } from '@angular/core';
import { IssueNotificationService } from 'src/app/issues/services/issue-notification.service';
import { IUserAuthData } from '../models/user-auth.model';

@Injectable({
  providedIn: 'root'
})
export class UserAuthDataService {

  private userInfoKey = "_userInfoKey";

  protected readonly roles = {
    Admin: 'Admin',
    Nurse: 'Nurse',
    Employee: 'Employee',
  };

  constructor(
    private _issueNotificationService: IssueNotificationService,
  ) {
  }

  cleanData() {
    sessionStorage.removeItem(this.userInfoKey);
  }


  private saveTokenInStorage(data: IUserAuthData): void {
    sessionStorage.setItem(this.userInfoKey, JSON.stringify(data))
  }



  private getAuthToken(): IUserAuthData | null {
    const tokenInfo = sessionStorage.getItem(this.userInfoKey);
    if (tokenInfo) {
      return JSON.parse(tokenInfo) as IUserAuthData;
    }
    return null;
  }


  saveToken(accessToken: string, refreshToken: string, expiration: Date) {

    //const tokenLifeTime = new Date();
    //tokenLifeTime.setMinutes(tokenLifeTime.getMinutes() + 10);

    let tokenData = this.getUserInfoFromToken(accessToken);
    this.saveTokenInStorage({
      accessToken: accessToken,
      refreshToken: refreshToken,
      expiration: expiration,//tokenLifeTime,
      currentRole: tokenData.Role
    });
    //initialize the service for issue notifications
    this._issueNotificationService.initialize();

  }

  getToken() {
    var token = this.getAuthToken();
    return token?.accessToken;
  }

  getRefreshToken() {
    var token = this.getAuthToken();
    return token?.refreshToken;
  }

  getUserId() {
    let tokenData = this.getJsonFromToken();
    return tokenData.Id;
  }

  isTokenValid(): boolean {
    let tokenData = this.getJsonFromToken();

    if (!tokenData || this.hasRefreshTokenExpired()) {
      return false;
    }

    return true;
  }

  hasRefreshTokenExpired(): boolean {
    const userInfo = this.getAuthToken();
    if (!userInfo) { return true; }
    const hasExpired = new Date(userInfo.expiration) <= new Date();
    console.log("Token expired?", hasExpired, userInfo.expiration);
    return hasExpired;
  }

  hasTokenExpired(): boolean {
    let tokenData = this.getJsonFromToken();

    if (!tokenData || this.getDateFromNumber(tokenData.exp) <= new Date()) {
      return true;
    }

    return false;
  }

  protected getDateFromNumber(dateNumber: number) {
    return new Date(dateNumber * 1000);
  }

  private getJsonFromToken() {
    const userData = this.getAuthToken();
    if (!userData) { return null; }
    return this.getUserInfoFromToken(userData.accessToken);
  }

  private getUserInfoFromToken(accessToken: string) {
    let dataArray = accessToken.split(".", 3);
    if (dataArray.length != 3) {
      return null;
    }
    let data = JSON.parse(atob(dataArray[1]));
    return data;
  }


  isDashboardUser(): boolean {

    return this.isUserAdmin() || this.isUserNurse() || this.isUserEmployee();
  }



  isUserAdmin(): boolean {
    const userData = this.getAuthToken();
    return userData?.currentRole === this.roles.Admin;
  }

  isUserNurse(): boolean {
    const userData = this.getAuthToken();
    return userData?.currentRole === this.roles.Nurse;
  }

  isUserEmployee(): boolean {
    const userData = this.getAuthToken();
    return userData?.currentRole === this.roles.Employee;
  }

  getSystemRoles() {
    return this.roles;
  }

  userHasAnyRole(roles: Array<string>): boolean {
    const userData = this.getAuthToken();
    if (!userData) { return false; }
    return roles.some(x => x.toLowerCase() == userData.currentRole.toLowerCase());
  }

}
