export interface IUserAuthData {
    accessToken: string;
    refreshToken: string;
    expiration: Date;
    currentRole: string
  }

  export enum UserRole{
      Admin="Admin",
      Nurse= 'Nurse',
      Employee='Employee',
      Any ='ANY',
  }