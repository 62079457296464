<div class="formsection">
  <form #formDirective="ngForm" (ngSubmit)="onSubmit(formDirective)" [formGroup]="this.passwordForm">
    <div class="password">
      <input [type]="hide1 ? 'password' : 'text'" placeholder="Password" formControlName="password" required
        maxlength="15" minlength="6">
      <div class="icon" (click)="hide1 = !hide1"><img src="../../../../assets/Login/{{hide1? 'hide.png' : 'show.png'}}">
      </div>
      <div *ngIf="passwordForm.get('password')?.invalid && 
					(passwordForm.get('password')?.dirty || passwordForm.get('password')?.touched)" class="err-msg">
        <p><span class="err-icon">!</span> {{getErrorMessage("password")}}</p>
      </div>
    </div>

    <div class="confirmPassword">
      <input [type]="hide2 ? 'password' : 'text'" placeholder="Confirm Password" formControlName="confirmPassword"
        required maxlength="15" minlength="6">
      <div class="icon" (click)="hide2 = !hide2"><img src="../../../../assets/Login/{{hide2? 'hide.png' : 'show.png'}}">
      </div>
      <div *ngIf="passwordForm.get('confirmPassword')?.invalid && 
					(passwordForm.get('confirmPassword')?.dirty || passwordForm.get('confirmPassword')?.touched)" class="err-msg">
        <p><span class="err-icon">!</span> {{getErrorMessage("confirmPassword")}}</p>
      </div>
    </div>
    <button type="submit" id="submit">
      {{getSubmitButtonLabel()}}
    </button>
  </form>
</div>