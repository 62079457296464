import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {IssueNotificationService} from 'src/app/issues/services/issue-notification.service';
import {MenuItemType} from 'src/app/util/models/menu-item-type-enum';
import {GeneralSettingsService} from "../../services/generalSettings.service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  issueType=MenuItemType.issues;

  menuElements = [
    {type: MenuItemType.store, url: "store-url", external:true, disabled:true},
    {type: MenuItemType.schedule_drip, url: "/schedule-drip"},
    {type: MenuItemType.issues, url: "/issues"},
    {type: MenuItemType.settings, url: "/settings"},
  ]

  constructor(
    private _router: Router,
    private _issueNotificationService: IssueNotificationService,
    private _settingsService: GeneralSettingsService
  ) { }

  ngOnInit(): void {
    
  }

  isElementSelected(url:string){
    return this._router.url.includes(url);
  }

  getPendingIssuesCountValue():number{
    return this._issueNotificationService.getPendingIssuesCountValue();
  }

}
