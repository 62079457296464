<div id="fullwidth">
	<app-background-carousel></app-background-carousel>
	<div class="formsection">
		<form #formDirective="ngForm" (ngSubmit)="onSubmit(formDirective)" [formGroup]="this.passwordResetForm">
			<div class="email">
				<input placeholder="Email" formControlName="email" required>
				<div *ngIf="passwordResetForm.get('email')?.invalid && 
					(passwordResetForm.get('email')?.dirty || passwordResetForm.get('email')?.touched)" class="err-msg">
					<p><span class="err-icon">!</span> {{getErrorMessage("email")}}</p>
				</div>
			</div>
			<button type="submit" id="submit">RESET PASSWORD</button>
		</form>
	</div>
</div>