import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageDialogService } from 'src/app/util/services/message-dialog.service';
import { AuthService } from '../../services/auth.service';
import { UserAuthDataService } from '../../services/user-auth-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent  implements OnInit {

  hide = true;
  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });
  constructor(
    private _router: Router,
    private authService: AuthService,
    private userAuthDataService: UserAuthDataService,
    private messageDialogService: MessageDialogService,
  ) {

  }
  ngOnInit() {
    this.initForms();
  }
  initForms() {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  getErrorMessage(control: string) {
    switch (control) {
      case 'email':
        return this.loginForm.get(control)?.hasError('required') ? 'Email required' : '';
      case 'password':
        return this.loginForm.get(control)?.hasError('required') ? 'Password required' : '';
      default:
        return '';
    }
  }
  onSubmit(formDirective: FormGroupDirective) {
    let email = this.loginForm.get("email");
    let password = this.loginForm.get("password");
    if(email?.errors? true:false || password?.errors? true:false){
      //this.messageDialogService.openDialogOneButton('Invalid Fields', 'Incorrect email or password', 'Continue');
      return
    }
    this.executeLogin(email?.value, password?.value);
  }

  executeLogin(email:string, password:string){
    this.authService.login(email, password).subscribe({
      next: (res:any) =>{
        //console.log(res);
        this.userAuthDataService.saveToken(res.accessToken, res.refreshToken, res.expiration);
        this._router.navigateByUrl('/schedule-drip');
      },
      error: (e:any)=>{
        console.log(e);
        if (e==='Unauthorized'){
          this.messageDialogService.openDialogOneButton('Unauthorized', 'Incorrect email or password', 'Continue');
        } else {
          this.messageDialogService.openDialogOneButton('Error', e.errors, 'Continue');
        }
      }
    });
  }

  isInputValid(inputName: string) {
    return this.loginForm.get(inputName)?.invalid && this.loginForm.get(inputName)?.dirty;
  }
  getInputMessage(inputName: string) {
    return this.loginForm.get(inputName)?.dirty ? this.getErrorMessage(inputName) : '';
  }

  goToResetPassword() {
    this._router.navigateByUrl('/password-reset');
  }

}
