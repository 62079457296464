import { Component, OnDestroy } from '@angular/core';
import { IssueNotificationService } from './issues/services/issue-notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy{
  title = 'app';

  constructor(
    private _issueNotificationService:IssueNotificationService
    ) { }

  ngOnDestroy(): void {
    this._issueNotificationService.stopConnection();
  }
}
