import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MessageDialogService } from 'src/app/util/services/message-dialog.service';
import { InvalidTokenDialogComponent } from '../../components/invalid-token-dialog/invalid-token-dialog.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {

  email: string = "";
  token: string = "";

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private authService: AuthService,
    private messageDialogService: MessageDialogService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.email = params.get('email')?? "";
      this.token = params.get('token')?? "";
      if(this.email === "" || this.token === ""){
        this.openInvalidDataDialog();
      } else{
        this.verifyEmailAndToken();
      }
    });
  }

  private verifyEmailAndToken() {
    this.authService.verifyCreatePasswordTokenAndEmail(this.email, this.token).subscribe({
      next: res => {
        console.log("creating password")
      },
      error: e => {
        this.openInvalidDataDialog();
      }
    });
  }

  goToLogin() {
    this._router.navigateByUrl('/login');
  }

  openInvalidDataDialog(): void {
    this.messageDialogService
    .openDialogOneButton('Invalid Data', 'The email or token parameters are invalid', 'Continue')
    .subscribe(result => {
      this.goToLogin();
    });
  }

}
