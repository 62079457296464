import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpBackend, HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { NetworkError } from 'src/app/util/models/networkError';
import { catchError, Observable } from 'rxjs';
import { UserAuthDataService } from './user-auth-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private urlController = 'AuthDashboard/';

  private httpClient: HttpClient;


  constructor(
    private http : HttpClient,
    private handler: HttpBackend,
    private userAuthDataService: UserAuthDataService
  ) { 
    this.httpClient = new HttpClient(handler);
  }
  
  getUrl(_url: string): string {
    return environment.apiUrl + _url;
  }
  getHeaders() {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };
  }

  login(email:string, password:string): any{
    let body = {email: email, password: password};
    return this.http.post(
      this.getUrl(`${this.urlController}login`),
      body,
      this.getHeaders()
    ).pipe(
      catchError(NetworkError.handleError)
    );
  }

  refreshToken():Observable<HttpEvent<any>>{
    let body = {accessToken: this.userAuthDataService.getToken() , refreshToken: this.userAuthDataService.getRefreshToken()};
    return this.httpClient.post<HttpEvent<any>>(
      this.getUrl(`${this.urlController}refresh-token`),
      body,
      this.getHeaders()
    );
  }

  requestPasswordReset(email:string){
    return this.http.get(
      this.getUrl(`${this.urlController}password-reset/${email}`),
      this.getHeaders()
    ).pipe(
      catchError(NetworkError.handleError)
    );
  }

  verifyPasswordResetTokenAndEmail(email:string, token:string){
    return this.http.get(
      this.getUrl(`${this.urlController}password-reset/${email}/${token}`),
      this.getHeaders()
    ).pipe(
      catchError(NetworkError.handleError)
    );
  }

  changePassword(email:string, token:string, password:string){
    let body = {email: email, token: token, password: password};
    return this.http.post(
      this.getUrl(`${this.urlController}password-reset`),
      body,
      this.getHeaders()
    ).pipe(
      catchError(NetworkError.handleError)
    );
  }
  

  verifyCreatePasswordTokenAndEmail(email:string, token:string){
    return this.http.get(
      this.getUrl(`${this.urlController}password-creation/${email}/${token}`),
      this.getHeaders()
    ).pipe(
      catchError(NetworkError.handleError)
    );
  }

  createPassword(email:string, token:string, password:string){
    let body = {email: email, token: token, password: password};
    return this.http.post(
      this.getUrl(`${this.urlController}password-creation`),
      body,
      this.getHeaders()
    ).pipe(
      catchError(NetworkError.handleError)
    );
  }

  deleteUser(userId: string):any {
    const url = this.getUrl(`${this.urlController}user/${userId}`);
    return this.http.delete(url, this.getHeaders()).pipe(catchError(NetworkError.handleError));
  }
  
}
