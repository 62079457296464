import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreatePasswordComponent } from './pages/create-password/create-password.component';
import { LoginComponent } from './pages/login/login.component';
import { PasswordChangeComponent } from './pages/password-change/password-change.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';

const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'password-reset', component: PasswordResetComponent, pathMatch: 'full' },
  { path: 'create-password/:email/:token', component: CreatePasswordComponent },
  { path: 'create-password/:email', component: CreatePasswordComponent },
  { path: 'create-password', component: CreatePasswordComponent },
  { path: 'change-password/:email/:token', component: PasswordChangeComponent },
  { path: 'change-password/:email', component: PasswordChangeComponent },
  { path: 'change-password', component: PasswordChangeComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
