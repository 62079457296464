import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-hour-selector',
  templateUrl: './hour-selector.component.html',
  styleUrls: ['./hour-selector.component.scss']
})
export class HourSelectorComponent implements OnInit {

  @Input() value: number = 0;
  @Input() minValue: number = 0;
  @Input() maxValue: number = 23;
  @Input() edit: boolean = false;
  
  @Output() valueChange = new EventEmitter<number>();

  options =[
    {id:0,value:"12am"},
    {id:1,value:"1am"},
    {id:2,value:"2am"},
    {id:3,value:"3am"},
    {id:4,value:"4am"},
    {id:5,value:"5am"},
    {id:6,value:"6am"},
    {id:7,value:"7am"},
    {id:8,value:"8am"},
    {id:9,value:"9am"},
    {id:10,value:"10am"},
    {id:11,value:"11am"},
    {id:12,value:"12pm"},
    {id:13,value:"1pm"},
    {id:14,value:"2pm"},
    {id:15,value:"3pm"},
    {id:16,value:"4pm"},
    {id:17,value:"5pm"},
    {id:18,value:"6pm"},
    {id:19,value:"7pm"},
    {id:20,value:"8pm"},
    {id:21,value:"9pm"},
    {id:22,value:"10pm"},
    {id:23,value:"11pm"}
  ];

  constructor() { }

  ngOnInit(): void {
  }

  setNewValue(newValue:number){
    this.valueChange.emit(newValue);
  }
}
