import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageDialogConfig } from '../../models/message-dialog-config';

@Component({
  selector: 'app-message-dialog-one-button',
  templateUrl: './message-dialog-one-button.component.html',
  styleUrls: ['./message-dialog-one-button.component.scss']
})
export class MessageDialogOneButtonComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MessageDialogOneButtonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MessageDialogConfig,
    ) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  getTitle(){
    return this.data.title; 
  }
  getContent(){
    return this.data.content; 
  }
  getOkButtonLabel(){
    return this.data.okButtonLabel?? 'Continue'; 
  }

}
