import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserRole } from '../auth/models';
import { UserAuthDataService } from '../auth/services/user-auth-data.service';

@Injectable({
  providedIn: 'root'
})
export class CanLoadByRoleGuard implements CanLoad {
  
  constructor(
    private userAuthDataService: UserAuthDataService,
    private _router: Router,
  ){}
  canLoad(
    route: Route,
    segments: UrlSegment[])
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      if (!this.userAuthDataService.isTokenValid()){
        this.goToLogin();
        return false;
      }
      let result = false;
      route.data?.role?.forEach((role: string) => {
        if(this.verifyRole(role)){
          result = true;
        }
      });
      if (!result) {
        this.goToLogin();
      }
      return result;
  }

  verifyRole(role: string) {
    switch (role) {
      case UserRole.Any:
        return this.userAuthDataService.isDashboardUser();
      case UserRole.Admin:
        return this.userAuthDataService.isUserAdmin();
      case UserRole.Employee:
        return this.userAuthDataService.isUserEmployee();
      case UserRole.Nurse:
        return this.userAuthDataService.isUserNurse();
      default:
        return false;
    }
  }

  goToLogin() {
    this._router.navigateByUrl('/login');
  }

  checkUserHasAnyRole (roles:Array<UserRole>){
    return this.userAuthDataService.userHasAnyRole(roles);
  }


 
}
