import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingsService {
  serviceUrl: string;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.apiUrl + "Settings";
  }

  private getHeaders() {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  }
}
export class StoreDto{
  public url!:string;
}
