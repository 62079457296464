import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogContinueCancelButtonsComponent } from '../components/message-dialog-continue-cancel-buttons/message-dialog-continue-cancel-buttons.component';
import { MessageDialogOneButtonComponent } from '../components/message-dialog-one-button/message-dialog-one-button.component';
import { MessageDialogConfig } from '../models/message-dialog-config';

@Injectable({
  providedIn: 'root'
})
export class MessageDialogService {

  constructor(public dialog: MatDialog,) { }

  openDialogOneButton(title:string, content:string, okButtonLabel:string){
    let config:MessageDialogConfig = {title: title, content: content, okButtonLabel: okButtonLabel};
    const dialogRef = this.dialog.open(MessageDialogOneButtonComponent, {
      width: '500px',
      data: config,
    });

    return dialogRef.afterClosed();
  }
  openDialogContinueCancel(title:string, content:string, okButtonLabel:string, cancelButtonLabel:string){
    let config:MessageDialogConfig = {title: title, content: content, okButtonLabel: okButtonLabel, cancelButtonLabel: cancelButtonLabel};
    const dialogRef = this.dialog.open(MessageDialogContinueCancelButtonsComponent, {
      width: '500px',
      data: config,
    });

    return dialogRef.afterClosed();
  }
}
