<section id="category">
    <div class="container-fluid">
        <div class="row">
            <app-menu-card class="col-md-6 col-lg-3" align="center" *ngFor="let element of menuElements"
                [type]="element.type" [selected]="isElementSelected(element.url)" [url]="element.url"
                           [disabled]="element.disabled" [external]="element.external"
                [totalNotifications]="element.type==issueType ? getPendingIssuesCountValue() : 0">
            </app-menu-card>
        </div>
    </div>
</section>
