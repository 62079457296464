import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from '../app-material/app-material.module';
import { BackgroundCarouselComponent } from './components/background-carousel/background-carousel.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { PasswordResetDialogComponent } from './components/password-reset-dialog/password-reset-dialog.component';
import { PasswordChangeComponent } from './pages/password-change/password-change.component';
import { CreatePasswordComponent } from './pages/create-password/create-password.component';
import { InvalidTokenDialogComponent } from './components/invalid-token-dialog/invalid-token-dialog.component';
import { ConfirmPasswordComponent } from './components/confirm-password/confirm-password.component';


@NgModule({
  declarations: [
    LoginComponent,
    BackgroundCarouselComponent,
    PasswordResetComponent,
    PasswordResetDialogComponent,
    PasswordChangeComponent,
    CreatePasswordComponent,
    InvalidTokenDialogComponent,
    ConfirmPasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    AuthRoutingModule
  ]
})
export class AuthModule { }
